import { ArrowLeftIcon } from "@primer/octicons-react";
import { Spinner } from "@primer/react";
import FixedHeader from "components/fixed-header";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { getCompanyDonations } from "store/company-donations/company-donations.action";
import companyDonationsSelectors from "store/company-donations/company-donations.selector";
import { MAX_TABLE_COLUMNS } from "utils/constants";
import routes from "utils/routes";

import * as S from "./all-donations.styled";
import CompanyDonationsTable from "./components/table-display";

const AllDonationsPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const companyName = useSelector(companySelectors.selectName);
  const donations = useSelector(companyDonationsSelectors.selectData);
  const page = useSelector(companyDonationsSelectors.selectPage);
  const donationsNum = useSelector(companyDonationsSelectors.selectResults);
  const inProgress = useSelector(companyDonationsSelectors.selectInProgress);
  const isFetched = useSelector(companyDonationsSelectors.selectIsFetched);

  React.useEffect(() => {
    dispatch(getCompanyDonations.request({ page: 1 }));
  }, []);

  return (
    <S.AllDonationsPageWrapper justifyContent="flex-start" gridGap={60}>
      <FixedHeader name={companyName} />
      <S.AllDonationsContainer justifyContent="flex-start" alignItems="flex-start" gridGap={40}>
        <S.AllDonationsHeading>Employees Donation History</S.AllDonationsHeading>
        <S.ClickableWrapper onClick={() => navigate(-1)}>
          <S.RowDisplay gridGap={18}>
            <ArrowLeftIcon size={25} />
            <S.GoBackText>Back</S.GoBackText>
          </S.RowDisplay>
        </S.ClickableWrapper>
        {inProgress && !isFetched ? (
          <S.ColumnDisplay width="100%" height="100%">
            <Spinner />
          </S.ColumnDisplay>
        ) : (
          <CompanyDonationsTable
            items={donations}
            totalItemsNum={donationsNum}
            currentPage={page}
            displayPagination={donationsNum > MAX_TABLE_COLUMNS}
            companyName={companyName}
          />
        )}
      </S.AllDonationsContainer>
    </S.AllDonationsPageWrapper>
  );
};

export default AllDonationsPage;
