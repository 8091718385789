import { Spinner } from "@primer/react";
import CharityCard from "components/charity-card";
import EditDropdown from "components/edit-dropdown";
import FixedHeader from "components/fixed-header";
import VolunteerEventCard from "components/volunteer-event-card";
import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import authSelectors from "store/auth/auth.selector";
import { getCharities, getMoreWallCharities } from "store/charities/charities.action";
import charityListSelectors from "store/charities/charities.selector";
import { getDashboardSummary } from "store/company/company.action";
import companySelectors from "store/company/company.selector";
import { IAppState } from "store/store.state";
import userSelectors from "store/user/user.selector";
import {
  fetchMoreVolunteerScheduledEvents,
  fetchVolunteerScheduledEvents,
} from "store/volunteer-scheduled/volunteer-scheduled.action";
import volunteerScheduledSelectors from "store/volunteer-scheduled/volunteer-scheduled.selector";
import colors from "theme/colors";
import { VolunteerEventItem } from "types/volunteer";
import { genEditLogoDropdown } from "utils/content";
import { cutDecimals, genRandomString } from "utils/helper";

import * as S from "./home.styled";

const HomePage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const containerRef = React.useRef();
  const [charityPage, setCharityPage] = React.useState<number>(1);
  const [eventsPage, setEventsPage] = React.useState<number>(1);

  const isEndReachedEvents = useSelector((state: IAppState) =>
    volunteerScheduledSelectors.selectEndReached(state, "ACTIVE"),
  );
  const isVolActive = useSelector((state: IAppState) =>
    volunteerScheduledSelectors.selectInProgress(state, "ACTIVE"),
  );
  const isWallEndReached = useSelector((state: IAppState) =>
    charityListSelectors.selectIsWallEndReached(state),
  );
  const charitiesInProgress = useSelector((state: IAppState) =>
    charityListSelectors.selectInProgress(state),
  );

  const companyName = useSelector(companySelectors.selectName);
  const logoUrl = useSelector(companySelectors.selectLogoUrl);
  const companySummary = useSelector(companySelectors.selectDashboardSummary);
  const inProgress = useSelector(companySelectors.selectInProgress);
  const eventsData = useSelector((state: IAppState) =>
    volunteerScheduledSelectors.selectData(state, "ACTIVE"),
  );
  const user = useSelector(userSelectors.selectData);
  const role = useSelector(authSelectors.selectRole);

  const charityData = useSelector((state: IAppState) =>
    charityListSelectors.selectData(state, "wall"),
  );

  React.useEffect(() => {
    dispatch(getDashboardSummary.request());
    dispatch(getCharities.request({ tab: "wall" }));
    dispatch(fetchVolunteerScheduledEvents.request({ params: { page: 1 }, status: "ACTIVE" }));
  }, []);

  // State to track the selected tab
  const [selectedTab, setSelectedTab] = React.useState<string>("charities");

  // Function to handle tab selection
  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const handleLoadMoreCharities = (): void => {
    dispatch(getMoreWallCharities.request({ tab: "wall", page: charityPage + 1 }));
    setCharityPage(charityPage + 1);
  };

  const handleLoadMoreEvents = (): void => {
    dispatch(
      fetchMoreVolunteerScheduledEvents.request({
        params: { page: eventsPage + 1 },
        status: "ACTIVE",
      }),
    );
    setEventsPage(eventsPage + 1);
  };

  const sections = Object.keys(charityData);
  const charities = sections.flatMap((key) => charityData[key]?.data) || [];

  return (
    <S.HomeContainer justifyContent="flex-start">
      <S.HomeInnerContainer justifyContent="flex-start" gridGap={28}>
        <S.HomeTopContainer justifyContent="flex-start" background={colors.white()}>
          <FixedHeader name={companyName} />
          <S.RowDisplay width="80%" alignItems="flex-start" justifyContent="flex-start" flexWrap>
            <S.Welcome>Welcome to Fundwurx, {user?.firstName ?? ""}!</S.Welcome>
          </S.RowDisplay>
          <S.HomeFrame
            gridGap={40}
            isTransparent
            justifyContent="space-between"
            alignItems="center"
          >
            <S.HomeFrameFlexContainer style={{ gridColumnGap: 120, gridRowGap: 40 }} flexWrap>
              <S.RowDisplay gridGap={16}>
                <S.ColumnDisplay gridGap={8} alignItems="flex-start">
                  <S.Name>Fundwurx for</S.Name>
                  {inProgress ? (
                    <S.LogoWrapper>
                      <Spinner />
                    </S.LogoWrapper>
                  ) : (
                    <>
                      {logoUrl ? (
                        <S.LogoWrapper>
                          {role === "admin" && (
                            <EditDropdown items={genEditLogoDropdown(logoUrl)} />
                          )}
                          <S.LogoStyled src={logoUrl} />
                        </S.LogoWrapper>
                      ) : (
                        <S.LogoBorderWrapper>
                          {role === "admin" && (
                            <EditDropdown items={genEditLogoDropdown(logoUrl)} />
                          )}
                          <S.DefaultLogoStyled />
                        </S.LogoBorderWrapper>
                      )}
                    </>
                  )}
                </S.ColumnDisplay>
              </S.RowDisplay>

              <S.BubblesContainer justifyContent="space-around" gridGap={20}>
                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{cutDecimals(companySummary?.numCharities || 0)}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total # of nonprofits supported</S.HomeLabel>
                </S.HomeBubble>

                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{`$${cutDecimals(companySummary?.totalDonationValue || 0)}`}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total amount donated by employees</S.HomeLabel>
                </S.HomeBubble>

                <S.HomeBubble gridGap={8}>
                  <S.HomeNumber style={{ fontWeight: "800" }}>
                    {inProgress ? (
                      <Spinner />
                    ) : (
                      <>{cutDecimals(companySummary?.numEmployeesDonated || 0)}</>
                    )}
                  </S.HomeNumber>
                  <S.HomeLabel>Total # of employees who donated </S.HomeLabel>
                </S.HomeBubble>
              </S.BubblesContainer>
            </S.HomeFrameFlexContainer>
          </S.HomeFrame>
        </S.HomeTopContainer>
        <S.RowDisplay width="100%" flexWrap gridGap={20} justifyContent="flex-start">
          <S.RowDisplay alignItems="flex-start" justifyContent="flex-start" gridGap={0}>
            <S.TabSwitch
              key={genRandomString()}
              isSelected={selectedTab === "charities"}
              isFirst
              isLast={false}
              onClick={() => handleTabClick("charities")}
            >
              <S.TabSwitchText
                onClick={() => handleTabClick("charities")}
                isSelected={selectedTab === "charities"}
              >
                Nonprofit Partners
              </S.TabSwitchText>
            </S.TabSwitch>
            <S.TabSwitch
              key={genRandomString()}
              isSelected={selectedTab === "volunteer"}
              isFirst={false}
              isLast
              onClick={() => handleTabClick("volunteer")}
            >
              <S.TabSwitchText
                onClick={() => handleTabClick("volunteer")}
                isSelected={selectedTab === "volunteer"}
              >
                Active Volunteer Events
              </S.TabSwitchText>
            </S.TabSwitch>
          </S.RowDisplay>
        </S.RowDisplay>
        {selectedTab === "charities" && (
          <S.WhiteBgContainer>
            {charities.length > 0 ? (
              <InfiniteScroll
                pageStart={charityPage || 1}
                loadMore={handleLoadMoreCharities}
                hasMore={!isWallEndReached && !charitiesInProgress}
                useWindow={false}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                getScrollParent={() => containerRef.current}
              >
                <S.EventContainer
                  flexWrap
                  width="100%"
                  gridGap={20}
                  alignItems="stretch"
                  justifyContent="flex-start"
                >
                  {charities.map((card) => (
                    <CharityCard
                      {...{ ...card, backed: false }}
                      key={genRandomString()}
                      isAdminView={false}
                      isEveryOrg={!!card.slug || false}
                      backFromHome
                    />
                  ))}
                </S.EventContainer>
              </InfiniteScroll>
            ) : (
              <p> No items to display. </p>
            )}
          </S.WhiteBgContainer>
        )}
        {selectedTab === "volunteer" && (
          <S.WhiteBgContainer>
            {eventsData.length > 0 ? (
              <InfiniteScroll
                pageStart={eventsPage || 1}
                loadMore={handleLoadMoreEvents}
                hasMore={!isEndReachedEvents && !isVolActive}
                useWindow={false}
                loader={
                  <div className="loader" key={0}>
                    Loading ...
                  </div>
                }
                getScrollParent={() => containerRef.current}
              >
                <S.EventContainer flexWrap width="100%" gridGap={20} justifyContent="flex-start">
                  {eventsData.map((event: VolunteerEventItem) => (
                    <VolunteerEventCard
                      {...{ ...(event as VolunteerEventItem) }}
                      key={genRandomString()}
                      isAdminView
                    />
                  ))}
                </S.EventContainer>
              </InfiniteScroll>
            ) : (
              <p> No items to display. </p>
            )}
          </S.WhiteBgContainer>
        )}
      </S.HomeInnerContainer>
    </S.HomeContainer>
  );
};

export default HomePage;
