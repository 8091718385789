import { Pagination } from "@primer/react";
import React from "react";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { getCompanyDonations } from "store/company-donations/company-donations.action";
import { CompanyDonations } from "types/user";
import { MAX_TABLE_COLUMNS } from "utils/constants";
import {
  ANON_COMPANY_DONATIONS_TABLE_HEADING,
  COMPANY_DONATIONS_TABLE_HEADING,
} from "utils/content";
import { formatDate, genRandomString } from "utils/helper";

import * as S from "../all-donations.styled";

const CompanyDonationsTable: React.FC<{
  items: CompanyDonations[];
  currentPage: number;
  totalItemsNum: number;
  displayPagination: boolean;
  companyName: string;
}> = ({ items, totalItemsNum, displayPagination, currentPage, companyName }): JSX.Element => {
  const dispatch = useDispatch();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  return totalItemsNum > 0 ? (
    <S.ColumnDisplay alignItems="flex-start" justifyContent="flex-start">
      {isTablet ? (
        <S.ColumnDisplay gridGap={16}>
          {items.map((user) => (
            <S.Table.Wrapper key={genRandomString()}>
              <S.Table.Body>
                {companyName !== "Motive" && (
                  <>
                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()}>
                        {COMPANY_DONATIONS_TABLE_HEADING[0]}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={!displayPagination} isFirstElem showTopBorder>
                        {user?.userFirstName}
                      </S.Table.Data>
                    </S.Table.Row>

                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {COMPANY_DONATIONS_TABLE_HEADING[1]}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={displayPagination}>
                        {user?.userLastName}
                      </S.Table.Data>
                    </S.Table.Row>
                    <S.Table.Row>
                      <S.Table.Heading key={genRandomString()} hideRadius>
                        {COMPANY_DONATIONS_TABLE_HEADING[2]}
                      </S.Table.Heading>
                      <S.Table.Data hideBorder={displayPagination}>
                        {user?.userDepartment}
                      </S.Table.Data>
                    </S.Table.Row>
                  </>
                )}
                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {COMPANY_DONATIONS_TABLE_HEADING[3]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={displayPagination}>{user?.charityName}</S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {COMPANY_DONATIONS_TABLE_HEADING[4]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={displayPagination}>{user?.memo}</S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius>
                    {COMPANY_DONATIONS_TABLE_HEADING[5]}
                  </S.Table.Heading>
                  <S.Table.Data hideBorder={displayPagination}>
                    {formatDate(user.date)}
                  </S.Table.Data>
                </S.Table.Row>

                <S.Table.Row>
                  <S.Table.Heading key={genRandomString()} hideRadius showBottomRadius>
                    {COMPANY_DONATIONS_TABLE_HEADING[6]}
                  </S.Table.Heading>
                  <S.Table.Data
                    hideBorder={displayPagination}
                  >{`$${user?.amount?.toFixed()}`}</S.Table.Data>
                </S.Table.Row>
              </S.Table.Body>
            </S.Table.Wrapper>
          ))}
        </S.ColumnDisplay>
      ) : (
        <S.Table.Wrapper>
          <S.Table.Body>
            <S.Table.Row>
              {companyName !== "Motive"
                ? COMPANY_DONATIONS_TABLE_HEADING.map((heading) => (
                    <S.Table.Heading key={genRandomString()} style={{ textAlign: "center" }}>
                      {heading}
                    </S.Table.Heading>
                  ))
                : ANON_COMPANY_DONATIONS_TABLE_HEADING.map((heading) => (
                    <S.Table.Heading key={genRandomString()} style={{ textAlign: "center" }}>
                      {heading}
                    </S.Table.Heading>
                  ))}
            </S.Table.Row>

            {items.map((donation) => (
              <S.Table.Row key={genRandomString()}>
                {companyName !== "Motive" && (
                  <>
                    <S.Table.Data hideBorder={displayPagination}>
                      {donation?.userFirstName}
                    </S.Table.Data>
                    <S.Table.Data>{donation?.userLastName}</S.Table.Data>
                    <S.Table.Data>{donation?.userDepartment}</S.Table.Data>
                  </>
                )}
                <S.Table.Data>{donation?.charityName}</S.Table.Data>
                <S.Table.Data>{donation?.memo}</S.Table.Data>
                <S.Table.Data>{formatDate(donation?.date)}</S.Table.Data>
                <S.Table.Data style={{ textAlign: "center" }}>{`$${donation.amount.toFixed(
                  2,
                )}`}</S.Table.Data>
              </S.Table.Row>
            ))}
          </S.Table.Body>
        </S.Table.Wrapper>
      )}

      {displayPagination && (
        <S.PaginationWrapper>
          <Pagination
            pageCount={Math.ceil(totalItemsNum / MAX_TABLE_COLUMNS)}
            currentPage={currentPage}
            showPages={!isTablet}
            onPageChange={(e, n) => {
              e.preventDefault();
              if (n !== currentPage) {
                dispatch(getCompanyDonations.request({ page: n }));
              }
            }}
          />
        </S.PaginationWrapper>
      )}
    </S.ColumnDisplay>
  ) : null;
};

export default CompanyDonationsTable;
